import * as Yup from "yup"

const ageRestriction = new Date(Date.now()).getFullYear() - 18
const VALID_HOUSE_NUMBER = "Please input a valid house number"

const houseNumberRegex = /^(?!n\/a\b|N\/A\b|NA$|na$|Na$|nA$)[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/

export const beneficiaryValidationSchema = Yup.object().shape({
  isOthers: Yup.boolean(),
  beneficiaryFirstName: Yup.string().required("This field is required").trim(),
  beneficiaryLastName: Yup.string().required("This field is required").trim(),
  beneficiaryType: Yup.object().shape({
    value: Yup.string().required("This field is required"),
  }),
  beneficiaryOthers: Yup.string().when("beneficiaryType", {
    is: (beneficiaryType) => beneficiaryType.value === "others",
    then: Yup.string().required("This field is required").trim(),
  }),
  prescribingHospital: Yup.string().required("This field is required").trim(),
  prescribingDoctor: Yup.string().required("This field is required").trim(),
  prcNumber: Yup.string().required("This field is required").trim(),
  prescriptionDate: Yup.object({
    month: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    date: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    year: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
  }),
})

export const deliveryDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required").trim(),
  lastName: Yup.string().required("This field is required").trim(),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  viberNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    ),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  personalEmail: Yup.string()
    .email("Please input a valid email")
    .notOneOf(
      [Yup.ref("emailAddress"), null],
      "Personal email should not be the same with work email"
    )
    .required("This field is required"),
  designation: Yup.string().required("This field is required").trim(),

  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    date: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    year: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
  }),
  addressType: Yup.string().required("This field is required"),
  recipientMobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required").trim(),
    houseNumber: Yup.string()
      .matches(houseNumberRegex, VALID_HOUSE_NUMBER)
      .required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required").trim(),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required").trim(),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required").trim(),
    }),
  }),
  nps: Yup.string().required("This field is required").trim(),
  programServiceRating: Yup.object().shape({
    value: Yup.string().required("This field is required").trim(),
  }),
  sex: Yup.string().required("This field is required").trim(),
})

export const deliveryDetailsInitialValues = {
  firstName: "",
  lastName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    streetAddress: "",
    city: "",
    province: "",
    notes: "",
  },
  nps: "",
}

export const mechanicsValidationSchema = (employeeIDList, state) =>
  Yup.object().shape({
    agreeToMechanics: Yup.array().min(1, "This field is required"),
    employeeIDNumber: Yup.string()
      .oneOf(
        employeeIDList,
        "Your employee ID is not registered. Please contact HR for more info."
      )
      .required("This field is required"),
  })

export const addMedicineModalValidationSchema = Yup.object().shape({
  dailyIntake: Yup.number().test(
    "dailyIntake",
    "Daily intake must be in increments of 0.25",
    (value) => {
      const stringValue = value.toString()
      if (stringValue.includes(".")) {
        const decimalPart = parseInt(stringValue.split(".")[1])
        if (stringValue.split(".")[1].length === 1) {
          return (decimalPart * 10) % 25 === 0
        }
        return decimalPart % 25 === 0
      }
      return true
    }
  ),
})
